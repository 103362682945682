.App {
  text-align: center;
}

#root, .App {
  height:100%;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sd-root-modern {
  max-width: 1366px;
  margin: auto;
  width: 100vw;
  min-height: 90vh;
  padding-top: 10vh;
  --primary: #be8de0;
}

.sd-item__decorator {
  background-color: #734D8E !important;
  --primary: #be8de0;
  --background: #734D8E;
  --background-dim: #4d4d4d;
  --background-dim-light: #272222;
  --foreground: black;
}

.survey-message {
  padding-top: 40vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  font-family: Montserrat;
  background-color: white;
  border: 1px solid;
}

.kokoroTable th {
  font-family: Montserrat;
}

.innerModal {
  font-family: Montserrat;
}

h2, h3 {
  font-family: Montserrat;
}

.headerLinks {
  max-height: inherit;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  transition: opacity 300ms ease;
  background-color: #734D8E;
  padding: 10px;
}