.kheader {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 10vh;
}

table, tr {
    margin: auto;
    border: 1px solid;
    /* background: linear-gradient(45deg, #49a09d, #5f2c82); */    
}

tr:hover, td:hover {
    background-color: rgba(88, 77, 55, .2);
}

tr:nth-child(even) {
    background-color: #be8de0;
}

.optionMenu div {
    cursor: pointer;
    margin-bottom: 5px;
}

.knavbar {
    max-width: 1366px;
    margin: auto;
    background-color: #be8de0;
    height: 4vh;
    display: flex;
    justify-content: space-evenly;
}

.knavbar a {
    color: white !important;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
}

.kcontainer {
    padding-top: 1vh;
    color: #734D8E;
    background-color: #be8de0;
    text-align: center;
    font-size: 1.5em;
    height: 5vh;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    max-width: 1366px;
    width: 100vw;
    position: relative;
    margin: auto;
    text-align: center;
}

.sd-root-modern--mobile .sd-matrix__responsive-title {
    display: block !important;
}