.schStg {
    margin-bottom: 10px;
    margin: auto;
}

details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker {
    display: none;
}

.cohortAddButton {
    margin-top: 20px;
}

.kokoroTable td, .kokoroTable th {
    padding: 10px;
}

.innerModal > div {
    width: 100%;
    height: 65vh;
    background-color: white;
    padding-top: 10vh;
    padding-bottom: 10vh;
}